import { ConsentString } from 'consent-string';
import debug from './shared/debug';

const dlog = debug('arkadium-ads:video:gdpr');

let VENDORS;

/* eslint-disable */
VENDORS = [
    {
       "id":32,
       "name":"AppNexus Inc.",
       "purposeIds":[
           1,2,3
       ],
       "policyUrl":"https://www.appnexus.com/en/company/platform-privacy-policy"
    },
    {
       "id":52,
       "name":"The Rubicon Project, Inc. ",
       "purposeIds":[
           1,2,3
       ],
       "policyUrl":"http://www.rubiconproject.com/rubicon-project-yield-optimization-privacy-policy/"
    },

    {
       "id":165,
       "name":"SpotX",
       "purposeIds":[
          1,2,3
       ],
       "policyUrl":"https://www.spotx.tv/privacy-policy/"
    },
    {
       "id":36,
       "name":"RhythmOne, LLC",
       "purposeIds":[
          1,2,3
       ],
       "policyUrl":"https://www.rhythmone.com/privacy-policy"
    },
    {
       "id":69,
       "name":"OpenX",
       "purposeIds":[
          1,
          2,
          3
       ],
       "policyUrl":"https://www.openx.com/legal/privacy-policy/"
    },
    {
       "id":81,
       "name":"PulsePoint, Inc.",
       "purposeIds":[
          1,
          2,
          3,
       ],
       "policyUrl":"https://www.pulsepoint.com/privacy-policy"
    },
]
/* eslint-enable */

function createGDPRConsentData() {
    const cs = new ConsentString();

    const vendorsList = {
        vendorListVersion: 173,
        lastUpdated: '2019-10-31T16:00:20Z',
        purposes: [
            {
                id: 1,
                name: 'Information storage and access',
                description: 'The storage of information, or access to information that is already stored, on your device such as advertising identifiers, device identifiers, cookies, and similar technologies.',
            },
            {
                id: 2,
                name: 'Personalisation',
                description: 'The collection and processing of information about your use of this service to subsequently personalise advertising and/or content for you in other contexts, such as on other websites or apps, over time. Typically, the content of the site or app is used to make inferences about your interests, which inform future selection of advertising and/or content.',
            },
            {
                id: 3,
                name: 'Ad selection, delivery, reporting',
                description: 'The collection of information, and combination with previously collected information, to select and deliver advertisements for you, and to measure the delivery and effectiveness of such advertisements. This includes using previously collected information about your interests to select ads, processing data about what advertisements were shown, how often they were shown, when and where they were shown, and whether you took any action related to the advertisement, including for example clicking an ad or making a purchase. This does not include personalisation, which is the collection and processing of information about your use of this service to subsequently personalise advertising and/or content for you in other contexts, such as websites or apps, over time.',
            },
        ],
        vendors: VENDORS,
        features: [],
    };

    cs.setGlobalVendorList(vendorsList);
    cs.setCmpId(1);
    cs.setCmpVersion(1);
    cs.setConsentScreen(1);
    cs.setConsentLanguage('en');
    cs.setPurposesAllowed([1, 2, 3]);
    cs.setVendorsAllowed(VENDORS.map(v => v.id));

    return cs.getConsentString();
}

function addFrame() {
    if (window.frames.__cmpLocator) return;
    if (document.body) {
        const { body } = document;

        const iframe = document.createElement('iframe');
        iframe.name = '__cmpLocator';
        iframe.style.display = 'none';
        body.appendChild(iframe);
    } else {
        setTimeout(addFrame, 5);
    }
}

function cmpFunc({
    gdprApplies, iabConsentData, hasGlobalScope, responseCode, cmpLoaded,
}, command, version, callback) {
    dlog(`[setupGdpr] (cmpFunc) com: ${command} ver: ${version} callback: ${callback}`);
    if (command === 'ping') {
        callback({ gdprAppliesGlobally: gdprApplies, cmpLoaded }, responseCode);
    } else if (command === 'getConsentData') {
        callback({ consentData: iabConsentData, gdprApplies, hasGlobalScope }, responseCode);
    } else if (command === 'getVendorConsents') {
        callback({ metadata: iabConsentData, gdprApplies, hasGlobalScope }, responseCode);
    } else {
        callback(undefined, false);
    }
}

function cmpMsgHandler(event) {
    try {
        let json = event.data;
        const msgIsString = typeof json === 'string';
        if (msgIsString) {
            json = JSON.parse(json);
        }
        const call = json.__cmpCall;
        if (call) {
            window.__cmp(call.command, call.parameter, (retValue, success) => {
                const returnMsg = {
                    __cmpReturn: {
                        returnValue: retValue, success, callId: call.callId,
                    },
                };

                dlog(`[cmpMsgHandler] (returnMsg) ${returnMsg}`);
                event.source.postMessage(msgIsString ? JSON.stringify(returnMsg) : returnMsg, '*');
            });
        }
    /* eslint-disable */
    } catch (e) {
        //
    }
    /* eslint-enable */
}

let finishedCMPSetup = false;

export function setupGdpr(prebid, personalizedAds = false) {
    dlog(`[setupGdpr] personalizedAds = ${String(personalizedAds)} finishedCMPSetup = ${String(finishedCMPSetup)}`);
    if (!personalizedAds || finishedCMPSetup) return;
    prebid.que.push(() => {
        dlog('[setupGdpr] (arkadium_pbjs.setConfig)');
        prebid.setConfig({
            consentManagement: {
                gdpr: {
                    cmpApi: 'iab',
                    timeout: 8000,
                    allowAuctionWithoutConsent: true,
                },
                // usp: {
                //     cmpApi: 'iab',
                //     timeout: 8000
                // }
            },
        });
    });

    const iabConsentData = createGDPRConsentData();
    const gdprApplies = true; // true if gdpr Applies to the user, else false
    const hasGlobalScope = true; // true if consent data was retrieved globally
    const responseCode = true; // false if there was an error, else true
    const cmpLoaded = true; // true if iabConsentData was loaded and processed
    dlog(`[setupGdpr] iabConsentData = ${iabConsentData}`);

    addFrame();

    const cmpOptions = {
        iabConsentData,
        gdprApplies,
        hasGlobalScope,
        responseCode,
        cmpLoaded,
    };

    const prevCmp = window.__cmp;

    window.__cmp = (command, version, callback) => {
        if (prevCmp) {
            prevCmp(command, version, callback);
        }

        cmpFunc(cmpOptions, command, version, callback);
    };

    window.__cmp.msgHandlerVideo = cmpMsgHandler;
    if (window.addEventListener) {
        window.addEventListener('message', cmpMsgHandler, false);
    } else {
        window.attachEvent('onmessage', cmpMsgHandler);
    }

    finishedCMPSetup = true;
}
