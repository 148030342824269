"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Analytics = void 0;
var applicationinsights_web_1 = require("@microsoft/applicationinsights-web");
var Analytics = /** @class */ (function () {
    function Analytics() {
        this.backend = new applicationinsights_web_1.ApplicationInsights({
            config: {
                // https://portal.azure.com/#@arkadium.com/resource/subscriptions/9869e63d-1a26-41d9-8c79-c76cbeb96ad9/resourcegroups/adevrtisement-prod/providers/microsoft.insights/components/video-errors/overview
                instrumentationKey: '700f6de3-fa6e-4bb6-b558-c1917521f31a',
                disableAjaxTracking: true,
                maxBatchInterval: 0,
            },
        });
        this.backend.loadAppInsights();
    }
    Analytics.getInstance = function () {
        if (!this._instance) {
            this._instance = new Analytics();
        }
        return this._instance;
    };
    Analytics.prototype.event = function (name, data) {
        this.backend.trackEvent({ name: name }, data);
    };
    Analytics.prototype.error = function (err, details) {
        var exception;
        if (typeof err === 'string') {
            exception = new Error(err);
        }
        else if (err.g) {
            exception = new Error(err.g.errorCode + ': ' + err.g.errorMessage + '\n' + err.g.innerError);
        }
        else {
            exception = err;
        }
        console.error(exception);
        this.backend.trackException({ exception: exception }, details);
        this.backend.flush();
    };
    return Analytics;
}());
exports.Analytics = Analytics;
